import Storage from "@/common/Storage";
import types from "@/common/types";

export default {
  data() {
    return {
      user: "",
    };
  },
  computed: {
    authId() {
      const authorized = this.$store.getters.authorized;
      return !!authorized ? authorized.id : "";
    },
    storageName() {
      return !!this.authId ? `profile-${this.authId}` : "";
    },
  },
  methods: {
    async setUser() {
      const storedProfile = !!this.storageName
        ? Storage.get(this.storageName)
        : "";
      if (types.isObject(storedProfile)) {
        this.user = storedProfile.data;
      } else {
        Storage.removeStartWith("profile-");
        const response = await http.send("user", { _method: "GET" });
        if (http.responseAccepted(response)) this.user = response.data.user;
      }
      if (types.isObject(this.user))
        Storage.set(this.storageName, this.user, 3); // commented
    },
    async update(e) {
      if (!this.authId)
        return console.error("no user specified (admin profile)");
      Storage.removeStartWith("profile");
      const formData = new FormData(e.target);
      formData.set("_method", "PUT");
      const response = await http.send("/users/" + this.authId, formData);
      if (http.responseAccepted(response)) {
        this.user = response.data.user;
        Storage.set(this.storageName, this.user, 3); // commented
        http.popupMessage("success", messages.updated);
      }
    },
  },
  async created() {
    await this.setUser();
  },
};
