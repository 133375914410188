<template>
  <div>
    <base-card header="bg-dark-blue" v-if="!!user">
      <template #header>
        بياناتي
      </template>
      <template #body>
        <div class="row pt-1">
          <div class="col-md-6">
            <label>الإسم الكامل</label>
            <form-input
              id="name"
              :value="user.name"
              :disabled="true"
            ></form-input>
          </div>
          <div class="col-md-6">
            <label>إسم المستخدم</label>
            <form-input
              id="username"
              :value="user.username"
              :disabled="true"
            ></form-input>
          </div>
        </div>
        <div class="row pt-1">
          <div class="col-md-6">
            <label>البريد الإلكتروني</label>
            <form-input
              id="email"
              :value="user.email"
              :disabled="true"
            ></form-input>
          </div>
          <div class="col-md-6">
            <label>رقم الهاتف</label>
            <form-input
              id=" :"
              :value="user.mobile"
              :disabled="true"
            ></form-input>
          </div>
        </div>
        <div class="row pt-1">
          <div class="col-md-6">
            <label>الجنسية</label>
            <form-input
              id="nationality"
              :value="user.nationality"
              :disabled="true"
            ></form-input>
          </div>
          <div class="col-md-6">
            <label>الرقم الوطني / جواز السفر</label>
            <form-input
              id="national_number"
              :value="user.national_number"
              :disabled="true"
            ></form-input>
          </div>
        </div>
        <div class="row pt-1">
          <div class="col-md-6">
            <label>المحافظة</label>
            <form-input
              id=" :"
              :value="user.city"
              :disabled="true"
            ></form-input>
          </div>
          <div class="col-md-6">
            <label>المنطقة</label>
            <form-input
              id=" :"
              :value="user.area"
              :disabled="true"
            ></form-input>
          </div>
        </div>
        <div class="row pt-1">
          <div class="col-md-6">
            <label>إسم الشارع</label>
            <form-input
              id="street"
              :value="user.street"
              :disabled="true"
            ></form-input>
          </div>
          <div class="col-md-6">
            <label>رقم البناية</label>
            <form-input
              id="building"
              :value="user.building"
              :disabled="true"
            ></form-input>
          </div>
        </div>
        <div class="row pt-1">
          <div class="col-md-6">
            <div class="row">
              <div class="col-sm-4">
                <label>السنة</label>
                <form-input
                  id="year"
                  :value="user.year"
                  :disabled="true"
                ></form-input>
              </div>
              <div class="col-sm-4">
                <label>الشهر</label>
                <form-input
                  id="month"
                  :value="user.month"
                  :disabled="true"
                ></form-input>
              </div>
              <div class="col-sm-4">
                <label>اليوم</label>
                <form-input
                  id="day"
                  :value="user.day"
                  :disabled="true"
                ></form-input>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-sm-12">
                <label>صورة الهوية</label>
                <modal-btn
                  btn-class="btn-red nr-block-shadow"
                  target="#show-id-image"
                  :disabled="!idImage"
                >
                  {{ !!idImage ? "عرض" : "لا يوجد" }}
                </modal-btn>
              </div>
            </div>
          </div>
        </div>
      </template>

      <teleport to="#app">
        <confirm-model
          target-id="show-id-image"
          title="صورة الهوية"
          header-bg="bg-blue"
          confirm-btn="d-none"
          cancel-btn="btn-success btn-bg"
          cancel-text="تم"
        >
          <base-image
            classes="w-100"
            :src="idImage"
            alt="صورة الهوية"
          ></base-image>
        </confirm-model>
      </teleport>
    </base-card>

    <base-card class="mt-5" header="bg-dark-red" body="space-x-1">
      <template #header>
        كلمة المرور
      </template>
      <template #body>
        <span>
          لتغيير كلمة المرور
        </span>
        <router-link to="/change-password">أنقر هنا</router-link>
      </template>
    </base-card>
  </div>
</template>

<script>
import ConfirmModel from "@/components/UI/ConfirmModel";
import ModalBtn from "@/components/UI/ModalBtn";
import BaseCard from "@/components/UI/BaseCard";
import FormInput from "@/components/Forms/FormInput";
import BaseImage from "@/components/UI/BaseImage";
import profiles from "@/mixins/profiles";

export default {
  components: { BaseImage, FormInput, BaseCard, ModalBtn, ConfirmModel },
  mixins: [profiles],
  computed: {
    idImage() {
      if (!this.user || !this.user.image) return "";
      return `${this.$store.getters.publicPath}/storage/${this.user.image}`;
    },
    city() {
      return !!this.user && !!this.user.city ? this.user.city.content : "";
    },
    area() {
      return !!this.user && !!this.user.area ? this.user.area.content : "";
    },
  },
};
</script>
