<template>
  <img :src="src" :alt="alt" :class="classes" />
</template>

<script>
export default {
  props: {
    src: { required: true },
    alt: { default: "صورة" },
    classes: {},
  },
};
</script>
